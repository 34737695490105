import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/details-layout"
import Seo from "../../components/seo"
import * as styles from "../../css/styles.scss"
import "./shuttle.scss"

import Shuttle from "./components/shuttleme"

const ShuttleMe = () => (
  <Layout>
    <Seo title="Belize Inland Tours | Belize Inland Tours Shuttle Me Services " />
    <Shuttle/>
  </Layout>
)
 
export default ShuttleMe;                                                                                          
                           